<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="914.949"
    height="914.951"
    viewBox="0 0 914.949 914.951"
  >
    <path
      transform="translate(0 0)"
      fill="#fff"
      d="M457.474,914.951a460.827,460.827,0,0,1-92.2-9.294A454.951,454.951,0,0,1,201.7,836.822,458.816,458.816,0,0,1,35.951,635.547,455.183,455.183,0,0,1,9.294,549.673a461.937,461.937,0,0,1,0-184.394A454.967,454.967,0,0,1,78.129,201.7,458.818,458.818,0,0,1,279.4,35.951,455.2,455.2,0,0,1,365.277,9.294a461.933,461.933,0,0,1,184.394,0A454.951,454.951,0,0,1,713.253,78.13,458.818,458.818,0,0,1,879,279.406a455.183,455.183,0,0,1,26.656,85.873,461.942,461.942,0,0,1,0,184.394,454.952,454.952,0,0,1-68.835,163.581A458.816,458.816,0,0,1,635.544,879a455.2,455.2,0,0,1-85.873,26.656A460.827,460.827,0,0,1,457.474,914.951Zm49.24-571.083A134.5,134.5,0,0,0,372.252,478.331V701a90.908,90.908,0,0,0,181.815,0V486.088a32.469,32.469,0,1,0-64.937,0V701a25.972,25.972,0,1,1-51.943,0V478.331a69.549,69.549,0,0,1,69.528-69.525H628.738a90.909,90.909,0,1,0,0-181.818H297.583a90.909,90.909,0,1,0,0,181.818h25.639a32.469,32.469,0,1,0,0-64.937l-25.639,0a25.973,25.973,0,0,1,0-51.945l331.159,0a25.973,25.973,0,0,1,0,51.945Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'trackingTo'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    }
  }
}
</script>