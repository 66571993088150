import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import TrackingTo from "../components/icons/TrackingTo";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#5B50F6",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#292931",
        success: "#90E86D",
        warning: "#FFC107",
        dark: "#1B1B20",
      },
    },
  },
  icons: {
    values: {
      trackingTo: {
        name: "trackingTo",
        component: TrackingTo,
      },
    },
  },
});
