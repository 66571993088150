import firebase from "firebase";
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");
const firebaseConfig = {
  apiKey: "AIzaSyBmbUaUkzLbYw_Tph0Mmj8axx9NLnyne8Q",
  authDomain: "trackingto-3222b.firebaseapp.com",
  databaseURL: "https://trackingto-3222b.firebaseio.com",
  projectId: "trackingto-3222b",
  storageBucket: "trackingto-3222b.appspot.com",
  messagingSenderId: "365395369892",
  appId: "1:365395369892:web:8fb09226bd30a94f84eb31",
  measurementId: "G-QV5SSB6CZF",
};
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth;
export const firestore = firebase.firestore;
export const storage = firebase.storage;
export default firebase;
