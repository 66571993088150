<template>
  <v-app>
    <v-app-bar app color="dark" dark>
      <div class="d-flex align-center">
        <v-icon>$vuetify.icons.trackingTo</v-icon>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-progress-linear v-if="loading" indeterminate />
      <LMap ref="myMap" :zoom="mapZoom" style="z-index: 100">
        <LTileLayerGoogleMutant :apikey="mapsAPIKey" />
        <v-rotated-marker
          v-if="vehicle"
          :rotationAngle="vehicle.course"
          :lat-lng="vehicle.position"
          :icon="getIcon(vehicle.type)"
        >
          <LPopup :options="{ minWidth: 250, className: 'custom' }">
            <v-card flat color="dark">
              <v-row no-gutters class="d-flex align-center">
                <v-col class="pa-0" cols="4">
                  <VueSvgGauge
                    :start-angle="-110"
                    :end-angle="110"
                    :value="vehicle.speed / 20"
                    :separator-step="1"
                    :min="0"
                    :max="6"
                    :gauge-color="[
                      { offset: 0, color: '#0b8c5a' },
                      { offset: 50, color: '#f4c009' },
                      { offset: 100, color: '#de3a21' },
                    ]"
                    :scale-interval="0.1"
                  />
                  <div class="white--text inner-text">
                    <span class="subtitle-1">{{ vehicle.speed }}</span> <br />
                    <span class="caption">km/h</span>
                  </div>
                </v-col>

                <v-col class="pa-0 ml-3" cols="7">
                  <span class="white--text subtitle-1">{{ vehicle.name }}</span>
                  <br />
                  <span class="white--text body-2">{{ vehicle.date }}</span>
                </v-col>
              </v-row>
            </v-card>
          </LPopup>
        </v-rotated-marker>
      </LMap>
    </v-main>
    <v-snackbar v-model="error" app bottom color="error">
      {{ errorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click.stop="error = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import firebase from "@/services/firebase";
import moment from "moment";
import { LMap, LPopup } from "vue2-leaflet";
import truckSvg from "@/assets/truck.svg";
import { VueSvgGauge } from "vue-svg-gauge";
import { latLngBounds, Icon, LatLng } from "leaflet";
import Vue2LeafletGoogleMutant from "vue2-leaflet-googlemutant";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
export default {
  name: "App",

  components: {
    LMap,
    LPopup,
    VueSvgGauge,
    LTileLayerGoogleMutant: Vue2LeafletGoogleMutant,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },

  data() {
    return {
      mapZoom: 2,
      vehicle: null,
      mapsAPIKey: "AIzaSyCVDmwl9YdmVDlYjf2_fb37RnUa0lCaBGE",
      loading: false,
      id: this.$route.params.id,
      error: false,
      errorMsg: null,
    };
  },
  methods: {
    getIcon: function () {
      const iconUrl = truckSvg;
      return new Icon({ iconUrl, iconSize: [38, 95] });
    },
    onError: function (message) {
      this.error = true;
      this.errorMsg = message;
    },
    zoomToCoords: function (lat, lng, maxZoom) {
      try {
        const markerBounds = latLngBounds([[lat, lng]]);
        const map = this.$refs.myMap.mapObject;
        map.flyToBounds(markerBounds, { maxZoom });
      } catch (error) {
        this.onError(error.message);
      }
    },
  },
  async created() {
    try {
      this.loading = true;
      const db = firebase.firestore();
      if (this.id) {
        db.collection("trackings")
          .doc(this.id)
          .onSnapshot((doc) => {
            const {
              location: { latitude: lat, longitude: lng },
            } = doc.data();
            const date = moment(doc.data().updatedOn.value).format(
              "MMMM DD, h:mm:ss a"
            );
            this.vehicle = Object.assign(doc.data(), {
              position: new LatLng(lat, lng),
              focus: false,
              feedOpen: false,
              date: date,
            });
            this.zoomToCoords(lat, lng, 15);
          });
      } else {
        this.onError("Tracking ID is missing");
      }
    } catch (error) {
      this.onError(error.message);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.inner-text {
  text-align: center;
  margin-top: -35px;
  flex-direction: column;
  font-weight: bold;
}
</style>
